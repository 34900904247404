




























































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, PropSync } from 'vue-property-decorator'
import SubTab from '@/models/ui/SubTab'

@Component
export default class PageTab extends Vue {
  @Prop({ type: String })
  private title: string

  @Prop({ type: Array, default: () => [] })
  private tabs: Array<any>

  @Prop({ type: Array, default: () => [] })
  private subTabs: Array<SubTab>

  @Prop({ type: Boolean, default: true })
  private translateSubTabs: boolean

  @PropSync('selectedTab', { type: Number }) tabIndex!: number
  @PropSync('selectedSubTab', { type: Number }) buttonIndex!: number
}
